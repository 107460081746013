import React, { Component } from 'react';
import axios from '../../helper/Axios';
import { NotificationManager } from 'react-notifications';
import { navigate } from "gatsby";
import jwtVerifyAndDecode from '../../helper/verifyToken';
import amber_logo_blue from '../../images/amber-logo-blue.png';


export default class DatabaseUploadMTMSecure extends Component {

    state = {
        uploading: false,
        use_employee_code: true,
        downloading: false
    }

    componentDidMount(){
        jwtVerifyAndDecode((sessionStorage.token)).then((_decoded_token) =>{

        })
        .catch(() => { 
             navigate("/database-upload-mtm")
        })
    }

    downloadSampleFile = async () => {
        const { use_employee_code, downloading } = this.state;
        if(downloading){
            return;
        }
        this.setState({downloading: true});
        try {
            const response = await axios.get(`/v1/breadboard/data_sync/sample_file?use_employee_code=${use_employee_code ? 1 : 0}`, 
            {
                responseType: 'arraybuffer',
            });
            if(response && response.status === 201 && response.data && response.data.sso_url){ 
                window.location.href = response.data.sso_url;
                return;
            }
            const file_name = 'sample_mtm_file';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name}.csv`); 
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            NotificationManager.error((error && error.response && error.response.data && error.response.data ? error.response.data : 'Download failed') || 'Download Failed');
        }
        this.setState({downloading: false});
    }

    openFileSelector = () => {
        const { uploading } = this.state;
        if(uploading){
            return;
        }
        document.getElementById('uploadUsers').click()
    }

    uploadCSV = async (e) => {
        if(e.target.files){
            const { use_employee_code } = this.state;
            const formData = new FormData();
            formData.append('csv', e.target.files[0]);
            this.setState({uploading: true});
            try {
                const response = await axios.post(`/v1/breadboard/data_sync/upload?use_employee_code=${use_employee_code ? 1 : 0}`, formData, { headers: {'Content-Type': 'multipart/form-data' }});
                if(response && response.status === 200){
                    NotificationManager.success('Data Updated Successfully. You will receive an email within the next few hours with the details.');
                    this.closeModal();
                }
                if(response && response.status === 201 && response.data && response.data.sso_url){ 
                    window.location.href = response.data.sso_url;
                }
            } catch (error) {
                if(error.response){
                    if(error.response.status === 401){
                        NotificationManager.error("Link Expired");
                        this.goToHome();
                    } else {
                        NotificationManager.error((error && error.response && error.response.data && error.response.data ? error.response.data : 'Upload failed') || 'Upload Failed');
                    }
                }
            }
            this.setState({uploading: false});
        }
    }

    selectIdentifier = (e) => {
        this.setState({use_employee_code: JSON.parse(e.target.value)})
    }

    goToHome = () => {
        sessionStorage.clear();
        setTimeout(() => { navigate("/") }, 2000);
    }

    render() {
        const { uploading, use_employee_code, downloading } = this.state;
        return (
            <div className="column is-8 mar-auto pd-25 upload-section">

                <figure className="app-logo">
                    <img src={amber_logo_blue} width="130px" alt="amber logo"/>
                </figure>
                <div className="columns is-mobile">
                    <div className="column no-pd-bottom"><h1 className="login-heading no-margin-padding-bottom font-montserrat">Database Upload for Moments that Matter</h1></div>
                </div>
                <p className="font-14">Using this feature you trigger moments for your organisation by uploading the users who have undergone a moment change.</p>
                <br/>
                <article className="message is-link">
                    <div className="message-body has-text-grey-darker">
                        <h2 className="has-text-weight-bold mg-bottom-10"><u>Guidelines to Upload File</u></h2>
                        <div className="content">
                            <ul className="line-height-li-22">
                                <li><b>Position your sheet’s columns as formatted in the sample file.</b> The system will not accept files with altered column position.</li>
                                <li><b>Do not remove any columns from the file, regardless of if they’re not pertinent to your organization.</b></li>
                                <li><b>Date formats</b> must be in Day/Month/Year format, i.e. <code>DD-MM-YYYY</code>.</li>
                                <li>Ensure there are <b>no duplicate email ID and employee code.</b></li>
                                <li>Upload maximum <b>2000 users</b> at a time.</li>
                            </ul>
                        </div>
                    </div>
                </article>
                <hr/>
                <div>
                    <h4 className="has-text-weight-bold"><span className="tag is-link">Step 1</span> &nbsp;&nbsp;<u>Select Unique Identifier</u></h4>
                    <br/>
                    <div className="control has-text-centered has-text-link">
                        <label className="radio">
                            <input type="radio" onChange={this.selectIdentifier} name="employee_code" value={true} checked={use_employee_code}/>
                            &nbsp;<b>Employee Code</b>
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <label className="radio">
                            <input type="radio" onChange={this.selectIdentifier} name="email" value={false} checked={!use_employee_code}/>
                            &nbsp;<b>Email ID</b>
                        </label>
                    </div>
                </div>
                <hr/>
                <div>
                    <h4 className="has-text-weight-bold"><span className="tag is-link">Step 2</span> &nbsp;&nbsp;<u>Download & Upload CSV</u></h4>
                    <br/>
                    <div className="columns flex-wrap has-text-centered pd-10">
                        <div className="column is-paddingless">
                            {downloading ?  
                            <div className="db-button" onClick={this.downloadSampleFile}>
                                <h3 className="has-text-weight-bold mg-bottom-5">
                                    Downloading...
                                </h3>
                                <p><small>Please wait file is downloading.</small></p>
                            </div>
                            : <div className="db-button" onClick={this.downloadSampleFile}>
                                <h3 className="has-text-weight-bold mg-bottom-5">
                                    Download Sample File
                                </h3>
                                <p><small>Download CSV file which contains the sample format.</small></p>
                            </div>}
                        </div>
                        <div onClick={this.openFileSelector} className="column is-paddingless">
                            {uploading 
                            ? <div className="db-button">
                                <h3 className="has-text-weight-bold mg-bottom-5">
                                    Uploading...
                                </h3>
                                <p><small>Please wait, file is uploading.</small></p>
                            </div>
                            : <div className="db-button">
                                <h3 className="has-text-weight-bold mg-bottom-5">
                                    Upload Data File
                                </h3>
                                <p><small>Upload maximum <b>2000 users</b> at a time in CSV.</small></p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                {!uploading ? <input type="file" id='uploadUsers' accept=".csv" onChange={this.uploadCSV} hidden/> : null}
            </div>
        )
    }
}
